import React from "react"
import { graphql } from "gatsby"
import Layout2 from "../components/layout2"
import Image from "../components/Image"
import getMetatags from "../util/getMetatags"
import Seo from "../components/Seo"
import HubspotForm from 'react-hubspot-form'
import { TrustedBySimple } from "../components/trusted-by"
import { relativeToAbsulote } from "../util/common"

function BookADemo({ data: { nodeBookADemo }, pageContext: { isThankYou }, ...props }) {

  const {
    path,
    trustedByTitle,
    relationships,
    calendarUrl,
  } = nodeBookADemo

  const { left, hubspot, thankyou, trustedBy } = relationships

  const seo = getMetatags(
    nodeBookADemo.metatag,
    nodeBookADemo.relationships.OGImage?.localFile?.publicURL
  )

  seo.meta.push({
    name: "robots",
    content: "noindex, nofollow"
  })

  left.trustedBy = trustedBy
  left.trustedByTitle = trustedByTitle


  if (!isThankYou) {
    return (
      <Layout2
        side={<Side {...hubspot} path={path.alias} />}
        classesSide={" lg:bg-[url('/background/BookADemo.png')] bg-no-repeat bg-top lg:pt-20 "}
      >
        <Seo {...seo} />
        <Main left={left} />
      </Layout2>
    )
  } else {
    return (
      <Layout2
        side={<SideThankYou thankyou={thankyou} />}
      >
        <Seo {...seo} />
        <MainThankYou thankyou={thankyou} calendarUrl={calendarUrl} />
      </Layout2>
    )
  }

}

const Main = ({ left }) => {
  return (
    <div className="lg:pt-12 lg:px-12 pt-2 pb-8 px-8">
      <h1 className="text-blue text-2xl 2xl:text-subtitle-l-bold font-bold  lg:py-8 py-2" dangerouslySetInnerHTML={{ __html: left.title }}></h1>
      <div className="text-base lg:text-xl	lg:leading-8 pb-2" dangerouslySetInnerHTML={{ __html: relativeToAbsulote(left?.description_html?.processed) }}></div>
      <ul className="text-base lg:text-xl	lg:leading-8 list-none list-inside pb-0">
        {left.bullets.map((s, index) => (
          <li className="pb-3" key={index}><span className="text-lg lg:text-2xl">✓</span> {s}</li>
        ))}
      </ul>
      <p className="text-base lg:text-xl	lg:leading-8 pb-2">{left.description}</p>
      {/*       <Image image={left.relationships.image} alt={left.image.alt} /> */}

      <TrustedBySimple
        items={left.trustedBy}
        title={left.trustedByTitle}
        className="py-11 pb-16"
        h4ClassName="mb-10 "
      />
    </div>
  )
}

const MainThankYou = ({ thankyou, calendarUrl }) => {

  return (
    <div className="lg:pt-12 lg:px-12 pt-8 px-8 pb-24 ">
      <h1 className="text-5xl py-8 leading-[3.5rem]">{thankyou.title}</h1>
      <p className="text-3xl pb-12 font-bold">{thankyou.description}</p>
      <div className="text-2xl pb-4" dangerouslySetInnerHTML={{ __html: relativeToAbsulote(thankyou?.description_html?.processed) }} />

      <iframe title="Calendar" src={calendarUrl} width={'100%'} height={'810px'} className="" />
    </div>
  )
}


const Side = ({ portal_id, form_id, title, sub_title, path }) => {


  const ready = (form) => {

    //console.log('Form ready!')
    //console.log(form)

    var container1 = document.getElementsByClassName("legal-consent-container")[0];
    var fieldset1 = container1.closest('fieldset')
    var container2 = document.getElementsByClassName("hs_receive_news")[0];
    var fieldset2 = container2.closest('fieldset')

    /*
    console.log(container1)
    console.log(fieldset1)
    console.log(container2)
    console.log(fieldset2)
    */

    if (fieldset1 && fieldset2) {
      swapElements(fieldset1, fieldset2)
    }
  }

  const swapElements = (elm1, elm2) => {
    var parent1, next1,
      parent2, next2;

    parent1 = elm1.parentNode;
    next1 = elm1.nextSibling;
    parent2 = elm2.parentNode;
    next2 = elm2.nextSibling;

    parent1.insertBefore(elm2, next1);
    parent2.insertBefore(elm1, next2);
  }

  //const classes1 = mode === 'regular' ? '' : ' '
  //const classes2 = mode === 'regular' ? '' : ' '

  return (
    <div className="container p-6 lg:p-8 xl:p-12 2xl:p-20" >

      <div className="bg-white text-grey  rounded-xl py-12 px-10 " >
        <h2 className="text-blue  text-3xl lg:subtitle-m-bold font-bold py-4">{title}</h2>
        <p className="py-4">{sub_title}</p>
        <HubspotForm
          portalId={portal_id}
          formId={form_id}

          //cssRequired=""
          css=" "  // !important
          cssClass="sigthfull-hubspot"

          submitButtonClass="sigthfull-hubspot-submit-btn c-btn"
          errorClass="sigthfull-hubspot-error"
          errorMessageClass="sigthfull-hubspot-error-message"


          //onSubmit={(e) => console.log('Submit!')}
          //onFromSubmit={(e) => console.log('on form submit')}
          //onFormSubmitted={(e) => console.log('on onFormSubmitted')}
          onReady={ready}
          //loading={<div>Loading...</div>}
          redirectUrl={path + '/thank-you'}

        />
      </div>
    </div>
  )
}





const SideThankYou = ({ thankyou }) => {

  return (
    <div className="container lg:p-20 p-6 bg-blue h-[100%]">
      <div className=" text-grey py-12 px-10 rounded-xl">
        <Image image={thankyou.relationships.image} alt={thankyou.image.alt} />
      </div>
    </div >
  )
}

export default BookADemo

export const query = graphql`
  query bookADemo($id: String!) {
    nodeBookADemo(id: { eq: $id }) {
      id
      title
      trustedByTitle: field_title2
      path {
        alias
      }
      showCTA: field_show_book_demo

      testimonialShow : field_show_testimonials
      testimonialTitle : field_title_5

      metatag {
        attributes {
          content
          name
        }
        tag
      }
      calendarUrl:field_title
      title1: field_title3
      title2: field_title4
      relationships {
        left: field_hero {
          ...ParagraphGenericBlock
        }
        thankyou: field_paragraph_single{
          ...ParagraphGenericBlock
        }
        hubspot: field_hubspot {
          ...ParagraphHubspotContent
        }
        trustedBy: field_paragraph_repeter {
          image: field_image {
            title
            alt
          }
          relationships {
            image: field_image {
              uri {
                url
              }
              localFile {
                url
                publicURL
              }
            }
          }
        }
        box1: field_paragraph_repeater2 {
          ...ParagraphImageAndText
        }
        box2: field_items {
          ...ParagraphImageAndText
        }
        OGImage: field_image {
          localFile {
            url
            publicURL
          }
        }
      }
    }
  }
`
